import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {Button, Checkbox, DatePicker, Form, Radio, Select, Space} from 'antd';
import dayjs from 'dayjs';

import {LOCALE, paramsToObject} from '../../common';
import {TEXT} from '../../common/ru_RU';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';
import {EduSelectors} from '../../store/EducationStore';
import {AdminAPI} from '../../store/AdminStore/methods';
import ModalForm from '../UX/ModalForm';
import AnalyticsForm from './AnalyticsForm';
import RatingForm from './RatingForm';

const {COMMON} = LOCALE;
const {Item} = Form;

const statuses = ['new', 'repeat', 'adv', 'call', 'agent', 'other', 'pik', 'manual', 'exclude'];

const AnalyticsFilters = ({form}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
  }, []);

  const initialFilters = {...paramsToObject(searchParams)};

  const downloadFile = (data, name) => {
    const url = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  const getAnalyticXLSX = (data, inArchive) => {
    const excelFilters = {...data, ...form.getFieldsValue(), inArchive: inArchive};

    const {repeat, adv, call, agent, other, pik, manual, exclude} = excelFilters;
    excelFilters.statuses = {repeat, new: excelFilters.new, adv, call, agent, other, pik, manual, exclude};
    statuses.forEach((status) => {
      delete excelFilters[status];
    });

    excelFilters.start = dayjs(data.start, 'DD.MM.YYYY').isValid()
      ? dayjs(data.start, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : data.start;
    excelFilters.end = dayjs(data.end, 'DD.MM.YYYY').isValid()
      ? dayjs(data.end, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : data.end;
    setSearchParams({start: excelFilters.start, end: excelFilters.end, group: excelFilters.group});

    dispatch(AdminAPI.analytic.getAnalyticXLSX({...excelFilters, inArchive})).then((response) => {
      if (response.payload.headers['content-disposition'].includes('zip')) {
        downloadFile(response.payload.data, `Отчет с ${excelFilters.start} по ${excelFilters.end}.zip`);
      } else {
        downloadFile(response.payload.data, `Отчет с ${excelFilters.start} по ${excelFilters.end}.xlsx`);
      }
    });
  };

  const getAnalyticRating = (data) => {
    const dates = {
      start: dayjs(data.start, 'DD.MM.YYYY').isValid()
        ? dayjs(data.start, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : data.start,
      end: dayjs(data.end, 'DD.MM.YYYY').isValid() ? dayjs(data.end, 'DD.MM.YYYY').format('YYYY-MM-DD') : data.end,
    };
    setSearchParams({...paramsToObject(searchParams), start: dates.start, end: dates.end});

    dispatch(AdminAPI.analytic.getAnalyticRating(dates)).then((response) => {
      downloadFile(response.payload.data, `Рейтинг за период с ${dates.start} по ${dates.end}.xlsx`);
    });
  };

  const getFullAnalytic = (data) => {
    const formData = {
      start: dayjs(data.start, 'DD.MM.YYYY').isValid()
        ? dayjs(data.start, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : data.start,
      end: dayjs(data.end, 'DD.MM.YYYY').isValid() ? dayjs(data.end, 'DD.MM.YYYY').format('YYYY-MM-DD') : data.end,
      contractStatus: form.getFieldValue('contractStatus'),
    };

    setSearchParams({...paramsToObject(searchParams), start: formData.start, end: formData.end});

    dispatch(AdminAPI.analytic.getAnalyticFull(formData)).then((response) => {
      downloadFile(response.payload.data, `Данные за период с ${formData.start} по ${formData.end}.xlsx`);
    });
  };

  const getAnalyticBonuses = (data) => {
    const excelFilters = {...data, ...form.getFieldsValue()};
    const {repeat, adv, call, agent, other, pik, manual, exclude} = excelFilters;
    excelFilters.statuses = {repeat, new: excelFilters.new, adv, call, agent, other, pik, manual, exclude};

    statuses.forEach((status) => {
      delete excelFilters[status];
    });

    excelFilters.start = dayjs(data.start, 'DD.MM.YYYY').isValid()
      ? dayjs(data.start, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : data.start;
    excelFilters.end = dayjs(data.end, 'DD.MM.YYYY').isValid()
      ? dayjs(data.end, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : data.end;

    setSearchParams({start: excelFilters.start, end: excelFilters.end, group: excelFilters.group});

    dispatch(AdminAPI.analytic.getAnalyticBonuses(excelFilters)).then((response) => {
      downloadFile(response.payload.data, `Отчет с ${excelFilters.start} по ${excelFilters.end}.xlsx`);
    });
  };

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: 12}}>
      <div>
        <Item name="month" label={COMMON.MONTH} style={{marginTop: 0, marginBottom: 4, width: '100%'}}>
          <DatePicker format="MM.YYYY" picker="month" />
        </Item>
        <Item
          name="trainingProgramType"
          label={TEXT.PAGE_TITLES.PROGRAM_TYPES}
          style={{marginTop: 0, marginBottom: 4, width: '100%'}}>
          <Select placeholder="Все" allowClear>
            {programTypes.map((programType) => (
              <Select.Option key={programType?.id} value={programType?.id}>
                {programType?.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item name="counter" label="Тип счетчика" style={{marginTop: 0, marginBottom: 4, width: '100%'}}>
          <Select placeholder="Сумма">
            <Select.Option value="sum">Сумма</Select.Option>
            <Select.Option value="students">Слушатели</Select.Option>
            <Select.Option value="contracts">Договора</Select.Option>
          </Select>
        </Item>
        <Item name="contractStatus" label="Дата аналитики" style={{marginTop: 0, marginBottom: 4}}>
          <Radio.Group>
            <Radio value="primary">Дата первички</Radio>
            <Radio value="secondary">Дата вторички</Radio>
          </Radio.Group>
        </Item>
      </div>
      <div>
        <Item
          name="new"
          label="Первый договор у пользователя"
          valuePropName="checked"
          style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="repeat" label="Повторный договор" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="adv" label="Источник реклама" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="call" label="Источник обзвон" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="agent" label="Источник агент" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
      </div>
      <div>
        <Item name="other" label="Источник другое" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="pik" label="Источник ПИК" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="manual" label="Источник ручной" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
        <Item name="exclude" label="Источник исключить" valuePropName="checked" style={{marginTop: 0, marginBottom: 4}}>
          <Checkbox />
        </Item>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        <ModalForm
          onOk={(data) => getAnalyticXLSX(data, false)}
          title="Скачать отчет Excel"
          initialValues={initialFilters}
          modalProps={{okText: 'Скачать'}}
          сustomActions={[
            {
              label: 'Скачать архив',
              onClick: (data) => getAnalyticXLSX(data, true),
            },
          ]}
          formItems={<AnalyticsForm />}>
          <Button>Скачать отчет Excel</Button>
        </ModalForm>
        <ModalForm
          onOk={getAnalyticRating}
          title="Скачать рейтинг пользователей"
          initialValues={initialFilters}
          formItems={<RatingForm />}>
          <Button>Скачать рейтинг пользователей</Button>
        </ModalForm>
        <ModalForm
          onOk={getFullAnalytic}
          title="Скачать все данные по договорам"
          initialValues={initialFilters}
          formItems={<RatingForm />}>
          <Button>Скачать все данные по договорам</Button>
        </ModalForm>
        <ModalForm
          onOk={getAnalyticBonuses}
          title="Скачать отчет по бонусам"
          initialValues={initialFilters}
          formItems={<AnalyticsForm />}>
          <Button>Скачать отчет по бонусам</Button>
        </ModalForm>
      </div>
    </div>
  );
};

export default AnalyticsFilters;
