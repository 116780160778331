import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';

import {Row, Col, Form, Radio, Space, Select, Checkbox, Input, InputNumber} from 'antd';
import {
  ADMIN_RIGHTS,
  DEADSOULS_STATUSES,
  DELIVERY_TYPE,
  LOCALE,
  REGISTRY_STATUSES_ACTIVE,
  REGISTRY_STATUSES_ARCHIVE,
  SOURCE_TYPE,
} from '../../common';
import CheckedFormItem from '../UX/CheckedFormItem';
import StringDatePicker from '../UX/StringDatePicker';

import {EduSelectors} from '../../store/EducationStore';
import {AdminSelectors} from '../../store/AdminStore';
import {RegistySelectors} from '../../store/RegistryStore';
import {EduMaterialsAPI} from '../../store/EducationStore/methods';

const {Item} = Form;
const {REGISTRY, DATES, SYSTEM, LISTENERS} = LOCALE;

const RegistryFilters = ({form}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isStudentChangeQueryActive = searchParams.get('isStudentChangeQueryActive');
  const dispatch = useDispatch();
  const programTypes = useSelector(EduSelectors.programTypes);
  const programs = useSelector(EduSelectors.programs);
  const isLoading = useSelector(RegistySelectors.loading);
  const loading = useSelector(EduSelectors.loading);

  const programType = Form.useWatch('programTypeId', form);

  useEffect(() => {
    dispatch(EduMaterialsAPI.programTypes.getAll());
    if (isStudentChangeQueryActive === 'true') {
      form.setFieldValue('isStudentChangeQueryActive', true);
    }
  }, [isStudentChangeQueryActive]);

  useEffect(() => {
    if (programType) {
      dispatch(EduMaterialsAPI.programs.getByType({filters: {trainingProgramTypeId: programType}}));
    } else {
      dispatch(EduMaterialsAPI.programs.getAll());
    }
  }, [programType]);

  const formValue = (name) => form.getFieldValue(name);
  const operators = useSelector(AdminSelectors.operators);
  const adminInfo = useSelector(AdminSelectors.adminInfo);

  const canChangeOperators = adminInfo?.adminRights
    .map((right) => right.id)
    .includes(ADMIN_RIGHTS.changeOperatorInRegistry.id);

  const inArchive = Form.useWatch('inArchive', form);
  const isDateCustom = Form.useWatch('date', form);
  const isDateCheck = Form.useWatch('dateCheck', form);
  Form.useWatch('contractNumberFilter', form);
  Form.useWatch('sourceFilter', form);
  Form.useWatch('costFilter', form);
  Form.useWatch('isPayedFilter', form);
  Form.useWatch('isExamFinishedFilter', form);
  Form.useWatch('deliveryFilter', form);
  Form.useWatch('programFilter', form);
  Form.useWatch('programTypeFilter', form);
  Form.useWatch('deadSoulFilter', form);
  Form.useWatch('operatorFilter', form);

  return (
    <>
      <Row gutter={[8, 12]}>
        <Col xs={{span: 12}} xl={{span: 8}}>
          <Row gutter={[8, 12]}>
            <div style={{width: '100%'}}>
              <Item name={'inArchive'} style={{marginBottom: 6}}>
                <Radio.Group
                  disabled={isLoading}
                  onChange={(e) =>
                    e.target.value
                      ? form.setFieldValue('status', ['problem', 'inplace', 'other_archive'])
                      : form.setFieldValue('status', ['white', 'yellow', 'blue', 'red', 'problem', 'inplace'])
                  }>
                  <Space>
                    <Radio value={false}>{REGISTRY.ACITVE}</Radio>
                    <Radio value={true}>{REGISTRY.ARCHIVE}</Radio>
                  </Space>
                </Radio.Group>
              </Item>
              <Item name="status" rules={[{type: 'array'}]} style={{marginBottom: 6}}>
                <Select
                  disabled={isLoading}
                  mode="multiple"
                  allowClear
                  placeholder="Выберете статус"
                  options={inArchive ? REGISTRY_STATUSES_ARCHIVE : REGISTRY_STATUSES_ACTIVE}
                />
              </Item>
            </div>
            <div style={{width: '100%'}}>
              <Space direction="vertical" size="small">
                <Item
                  name="dateCheck"
                  valuePropName="checked"
                  label="Фильтр по датам"
                  shouldUpdate
                  style={{marginBottom: 0}}>
                  <Checkbox disabled={isLoading} />
                </Item>
                <Item name="dateType" noStyle>
                  <Radio.Group disabled={!formValue('dateCheck') || isLoading}>
                    <Radio value="primary">{REGISTRY.CONTRACT}</Radio>
                    <Radio value="secondary">{REGISTRY.SECONDARY_DOCS}</Radio>
                  </Radio.Group>
                </Item>

                <Item name="date">
                  <Radio.Group
                    disabled={!formValue('dateCheck') || isLoading}
                    onChange={(e) => {
                      if (e.target.value === 'custom') {
                        form.setFieldValue('dateTo', dayjs());
                      } else {
                        form.setFieldsValue({dateFrom: null, dateTo: null});
                      }
                    }}>
                    <Space direction="vertical">
                      <Radio value={'today'}>{DATES.TODAY}</Radio>
                      <Radio value={'three'}>{DATES.LAST_THREE_DAYS}</Radio>
                      <Radio value={'custom'}>
                        <Space>
                          {`${DATES.DATES_FROM} `}
                          <Item
                            name="dateFrom"
                            rules={[
                              {required: isDateCustom === 'custom' && isDateCheck, message: 'Укажите начальную дату'},
                            ]}
                            noStyle>
                            <StringDatePicker format={'DD.MM.YYYY'} disabled={!formValue('dateCheck')} />
                          </Item>
                          {` ${DATES.TO} `}
                          <Item
                            name="dateTo"
                            rules={[
                              {required: isDateCustom === 'custom' && isDateCheck, message: 'Укажите конечную дату'},
                            ]}
                            noStyle>
                            <StringDatePicker format={'DD.MM.YYYY'} disabled={!formValue('dateCheck')} />
                          </Item>
                        </Space>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </Space>
            </div>
          </Row>
        </Col>
        <Col xs={{span: 24}} xl={{span: 16}}>
          <div style={{height: '100%'}}>
            <Row gutter={[24, 12]} style={{width: '100%'}}>
              <Col xs={{span: 24}} xl={{span: 13}}>
                <CheckedFormItem
                  label={REGISTRY.CONTRACT_NUMBER}
                  checkboxName="contractNumberFilter"
                  disabled={isLoading}>
                  <Item
                    noStyle
                    rules={[{required: formValue('contractNumberFilter')}]}
                    dependencies={['contractNumberFilter']}
                    label={REGISTRY.CONTRACT_NUMBER}
                    name={'contractNumber'}>
                    <Input
                      autoComplete="contractNumber"
                      id="contractNumber"
                      autoCorrect="contractNumber"
                      disabled={!formValue('contractNumberFilter')}
                      placeholder="2022-14Д"
                    />
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem label={REGISTRY.SOURCE} checkboxName="sourceFilter" disabled={isLoading}>
                  <Item noStyle rules={[{required: formValue('sourceFilter')}]} name={'sourceTypeId'}>
                    <Select disabled={!formValue('sourceFilter')} placeholder="Реклама">
                      {SOURCE_TYPE.map((source) => (
                        <Select.Option key={source.value} value={source.value}>
                          {source.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem label={REGISTRY.DELIVERY} checkboxName={'deliveryFilter'} disabled={isLoading}>
                  <Item name="deliveryTypeId" rules={[{required: formValue('deliveryFilter')}]} noStyle>
                    <Select disabled={!formValue('deliveryFilter')} placeholder="Без доставки">
                      {DELIVERY_TYPE.map((delivery) => (
                        <Select.Option key={delivery.value} value={delivery.value}>
                          {delivery.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem
                  label={LOCALE.EDUCATION.PROGRAM_TYPE}
                  checkboxName={'programTypeFilter'}
                  disabled={isLoading}>
                  <Item noStyle name="programTypeId" rules={[{required: formValue('programTypeFilter')}]}>
                    <Select
                      placeholder="БДД для водителей"
                      filterOption={(inputValue, option) =>
                        option.children.toLowerCase().includes(inputValue.toLowerCase())
                      }
                      showSearch
                      disabled={!formValue('programTypeFilter')}>
                      {programTypes.map((type) => (
                        <Select.Option value={type.id} key={type.id}>
                          {type.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem label={LOCALE.EDUCATION.PROGRAM} checkboxName={'programFilter'} disabled={isLoading}>
                  <Item noStyle name="programId" rules={[{required: formValue('programFilter')}]}>
                    <Select
                      placeholder="ОПП_06"
                      filterOption={(inputValue, option) =>
                        option.children.toLowerCase().includes(inputValue.toLowerCase())
                      }
                      showSearch
                      loading={loading}
                      disabled={!formValue('programFilter')}>
                      {programs.map((program) => (
                        <Select.Option key={program.id} value={program.id}>
                          {program.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem label={SYSTEM.DEADSOULS} checkboxName={'deadSoulFilter'} disabled={isLoading}>
                  <Item name="deadSoulStatusId" noStyle rules={[{required: formValue('deadSoulFilter')}]}>
                    <Select disabled={!formValue('deadSoulFilter')} placeholder="Повторная рассылка">
                      {DEADSOULS_STATUSES.map((status) => (
                        <Select.Option key={status.value} value={status.value}>
                          {status.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </CheckedFormItem>
             
                  <CheckedFormItem label={SYSTEM.OPERATORS} checkboxName="operatorFilter" disabled={isLoading}>
                    <Item name="operatorId" noStyle>
                     
                        <Select allowClear disabled={!formValue('operatorFilter')}>
                          {operators.map((operator) => (
                            <Select.Option key={operator.id} value={operator.id}>
                              {operator.shortName}
                            </Select.Option>
                          ))}
                        </Select>
                    
                    </Item>
                  </CheckedFormItem>
               
                <CheckedFormItem label={REGISTRY.SUM} checkboxName="costFilter" disabled={isLoading}>
                  <Space>
                    {SYSTEM.FROM + ' '}
                    <Item name="costMin" rules={[{required: formValue('costFilter')}]} noStyle>
                      <InputNumber disabled={!formValue('costFilter')} min={0} placeholder={0} />
                    </Item>
                    {SYSTEM.TO + ' '}
                    <Item name="costMax" rules={[{required: formValue('costFilter')}]} noStyle>
                      <InputNumber min={0} disabled={!formValue('costFilter')} placeholder={999999} />
                    </Item>
                  </Space>
                </CheckedFormItem>
              </Col>
              <Col xs={{span: 24}} xl={{span: 11}}>
                <CheckedFormItem label={REGISTRY.IS_PAYED} checkboxName={'isPayedFilter'} disabled={isLoading}>
                  <Item name="isPayed" rules={[{required: formValue('isPayedFilter')}]} noStyle>
                    <Radio.Group disabled={!formValue('isPayedFilter')}>
                      <Radio value={true}>{SYSTEM.YES}</Radio>
                      <Radio value={false}>{SYSTEM.NO}</Radio>
                    </Radio.Group>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem
                  label={LOCALE.EDUCATION.IS_EXAM_FINISHED}
                  checkboxName={'isExamFinishedFilter'}
                  disabled={isLoading}>
                  <Item name="isExamFinished" rules={[{required: formValue('isExamFinishedFilter')}]} noStyle>
                    <Radio.Group disabled={!formValue('isExamFinishedFilter')}>
                      <Radio value={true}>{SYSTEM.YES}</Radio>
                      <Radio value={false}>{SYSTEM.NO}</Radio>
                    </Radio.Group>
                  </Item>
                </CheckedFormItem>
                <CheckedFormItem
                  checkboxName="payedNotDoneFilter"
                  labelSpan={16}
                  label={REGISTRY.PAYED_NOT_DONE}
                  disabled={isLoading}
                />
                <CheckedFormItem
                  checkboxName="manualAddressFilter"
                  labelSpan={16}
                  label={REGISTRY.MANUAL_ADDRESS}
                  disabled={isLoading}
                />
                <CheckedFormItem
                  checkboxName="duplicateFilter"
                  labelSpan={16}
                  label={REGISTRY.CONTRACT_DUPLICATES}
                  disabled={isLoading}
                />
                <CheckedFormItem
                  checkboxName="isStudentChangeQueryActive"
                  labelSpan={16}
                  label={LISTENERS.CHANGE_QUERY}
                  disabled={isLoading}
                />
                <CheckedFormItem
                  checkboxName="isNeedDiplomCheck"
                  labelSpan={16}
                  label={LISTENERS.DIPLOMA_QUERY}
                  disabled={isLoading}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RegistryFilters;
