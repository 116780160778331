import React, {useState} from 'react';
import {Button, Form, Modal, Space} from 'antd';

import CustomForm from './CustomForm';
import {LOCALE} from '../../common';
const {SYSTEM} = LOCALE;

const ModalForm = ({
  onCancel = () => {},
  onOk = () => {},
  initialValues,
  formProps,
  formInstance,
  modalProps,
  title,
  children,
  сustomActions = [],
  closeOnOk = true,
  formItems,
}) => {
  const [form] = Form.useForm(formInstance);
  const [modalVisible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const handleCancel = () => {
    onCancel(form);
    hideModal();
  };

  const handleOk = (values) => {
    onOk(values);
    closeOnOk && hideModal();
  };

  const childrenWithHandler =
    children &&
    React.cloneElement(children, {
      onClick: showModal,
    });

  return (
    <>
      {childrenWithHandler || children}
      <Modal
        onCancel={handleCancel}
        onOk={form.submit}
        title={title}
        style={{textAlign: 'left'}}
        destroyOnClose
        open={modalVisible}
        footer={[
          <Space style={{justifyContent: 'center'}}>
            <Button onClick={handleCancel}>{SYSTEM.CANCEL}</Button>
            {сustomActions &&
              сustomActions.map((item) => (
                <Button
                  key={Math.random()}
                  onClick={() => {
                    form.validateFields().then((res) => {
                      item?.onClick(res);
                      hideModal();
                    });
                  }}
                  type="primary"
                  {...item.props}>
                  {item?.label}
                </Button>
              ))}
            <Button onClick={form.submit} type="primary">
              {modalProps?.okText || SYSTEM.SAVE}
            </Button>
          </Space>,
        ]}
        {...modalProps}>
        <CustomForm
          formInstance={form}
          onOk={handleOk}
          showButtons={false}
          initialValues={initialValues}
          layout={{labelCol: {span: 8}, wrapperCol: 18}}
          {...formProps}>
          {formItems}
        </CustomForm>
      </Modal>
    </>
  );
};

export default ModalForm;
