import {Divider, Form, Select} from 'antd';
import StringDatePicker from '../UX/StringDatePicker';

const AnalyticsForm = () => {
  return (
    <>
      <Form.Item
        name="start"
        rules={[{required: true, message: 'Пожалуйста укажите дату начала периода'}]}
        label="Дата начала периода">
        <StringDatePicker format="DD.MM.YYYY" style={{width: '100%'}} />
      </Form.Item>
      <Form.Item
        name="end"
        rules={[{required: true, message: 'Пожалуйста укажите дату окончания периода'}]}
        label="Дата окончания периода">
        <StringDatePicker format="DD.MM.YYYY" style={{width: '100%'}} />
      </Form.Item>
      <Form.Item
        name="group"
        rules={[{required: true, message: 'Пожалуйста укажите как группировать'}]}
        label="Группировать">
        <Select placeholder="Месяц" allowClear>
          <Select.Option value="day">День</Select.Option>
          <Select.Option value="week">Неделя</Select.Option>
          <Select.Option value="month">Месяц</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default AnalyticsForm;
